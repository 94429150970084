import { IComponentModel, IListComponentModel, LocaleHelper } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { GetServerSideProps } from "next";

import dynamic from "next/dynamic";

import getTranslations from "helpers/getTranslationsHelper";
import { captureMessage } from "@sentry/nextjs";
import { getCommonServerSideProps } from "../../../../helpers/getCommonServerSideProps";
import { ReactElement } from "react";

const Layout = dynamic(() => import("layouts/Layout"));

const MainScreen = dynamic(() => import("screens/MainScreen"));

export const getServerSideProps: GetServerSideProps = async (ctx: any) => {
  const setCacheHeader = await import("helpers").then(
    (mod) => mod.setCacheHeader
  );
  const getComponentsMediaListData = await import("SSRUtils").then(
    (mod) => mod.getComponentsMediaListData
  );

  const { commonProps } = await getCommonServerSideProps(ctx);

  setCacheHeader(ctx.res);

  const locale = LocaleHelper.getLocaleFromLanguage(ctx.query.language);

  const translations = await getTranslations(locale || ctx.locale);
  try {
    const { RedBeeMediaDataProvider } = await import(
      "../../../../../../common/src/providers/DataProvider/RedBeeMedia/RedBeeMediaDataProvider"
    );
    const dataProvider = new RedBeeMediaDataProvider();
    const screenData = await dataProvider.getComponentConfiguration(
      ctx.params.id
    );
    dataProvider.setLanguage(locale || ctx.locale);

    const componentData: IComponentModel[] = await getComponentsMediaListData(
      screenData?.Components as IListComponentModel[]
    );

    return {
      props: {
        ...commonProps,
        componentData,
        screenData,
        ...JSON.parse(JSON.stringify(translations)),
      },
    };
  } catch (e: any) {
    captureMessage(e.Message, {
      level: "warning",
      tags: {
        componentConfiguration: ctx?.params?.id,
        error: e,
      },
    });
    return {
      redirect: {
        destination: `/${commonProps.country}/${commonProps.lang}${ROUTES.PAGE_NOT_FOUND}`,
      },
      props: {
        ...commonProps,
      },
    };
  }
};

const MainScreenWrapper = (props: any) => {
  return <MainScreen {...props} />;
};

MainScreenWrapper.getLayout = (page: ReactElement) => {
  const props = page.props;
  return <Layout config={props?.config}>{page}</Layout>;
};

export default MainScreenWrapper;
